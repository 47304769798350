import { useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import AppBar from "@mui/material/AppBar";
import { useRouter } from "next/router";

import { getButtonAppearance } from "utils/button";
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "utils/types";
import { MdMenu } from "react-icons/md";
import MobileNavMenu from "./mobile-nav-menu";
import ButtonLink from "./button-link";
import NextImage from "./image";
import CustomLink from "./custom-link";
import LocaleSwitch from "../locale-switch";

const Navbar = ({ navbar, pageContext }) => {
  const router = useRouter();
  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false);

  return (
    <>
      {/* The actual navbar */}
      <nav
        className="py-6 sm:py-2 sticky top-0 z-50"
        style={{
          backgroundColor: "black",
          color: navbar.textColor,
          marginBottom: -100,
          zIndex: 10,
          // position: "relative",
        }}
      >
        <div className="container flex flex-row items-center justify-between">
          {/* Content aligned to the left */}
          <div className="flex flex-row items-center">
            {/*<Link href="/">*/}
            {/*  <p className="h-auto w-32">*/}
            {/*    {navbar.logo && (*/}
            {/*      <NextImage width="75" height="75" media={navbar.logo} />*/}
            {/*    )}*/}
            {/*  </p>*/}
            {/*</Link>*/}

            {navbar.textLogo ? (
              <Link href="/">
                <p className="h-auto w-32 py-2">
                  <NextImage width="120" height="50" media={navbar.textLogo} />
                </p>
              </Link>
            ) : null}
            {/* List of links on desktop */}
            <ul
              className="hidden list-none md:flex flex-row gap-4 items-baseline ml-10 text-xl"
              style={{ maxWidth: "55vw", overflowX: "auto" }}
            >
              {navbar.links.map((navLink) => (
                <li key={navLink.id}>
                  <CustomLink link={navLink} locale={router.locale}>
                    <div
                      className="px-2 py-1"
                      style={{ color: navbar.textColor, whiteSpace: "nowrap" }}
                    >
                      {navLink.text}
                    </div>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex">
            {/* Locale Switch Mobile */}
            {pageContext.localizedPaths && (
              <div className="md:hidden">
                <LocaleSwitch pageContext={pageContext} />
              </div>
            )}
            {/* Hamburger menu on mobile */}
            <button
              onClick={() => setMobileMenuIsShown(true)}
              className="p-1 block md:hidden"
            >
              <MdMenu className="h-8 w-auto" />
            </button>
            {/* CTA button on desktop */}
            {navbar.button && (
              <div className="hidden md:block">
                <ButtonLink
                  button={navbar.button}
                  appearance={getButtonAppearance("primary", "light")}
                  compact
                />
              </div>
            )}
            {/* Locale Switch Desktop */}
            {pageContext.localizedPaths && (
              <div className="hidden md:block">
                <LocaleSwitch pageContext={pageContext} />
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Mobile navigation menu panel */}
      {mobileMenuIsShown && (
        <MobileNavMenu
          navbar={navbar}
          closeSelf={() => setMobileMenuIsShown(false)}
        />
      )}
    </>
  );
};

Navbar.propTypes = {
  navbar: PropTypes.shape({
    logo: PropTypes.shape({
      image: mediaPropTypes,
      url: PropTypes.string,
    }),
    textLogo: PropTypes.shape({
      image: mediaPropTypes,
      url: PropTypes.string,
    }),
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
    color: PropTypes.string,
    textColor: PropTypes.string,
  }),
  initialLocale: PropTypes.string,
};

export default Navbar;
