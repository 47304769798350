import PropTypes from "prop-types";
import { MdClose, MdChevronRight } from "react-icons/md";
import {
  mediaPropTypes,
  linkPropTypes,
  buttonLinkPropTypes,
} from "utils/types";
import { useLockBodyScroll } from "utils/hooks";
import { getButtonAppearance } from "utils/button";
import ButtonLink from "./button-link";
import NextImage from "./image";
import CustomLink from "./custom-link";

const MobileNavMenu = ({ navbar, closeSelf }) => {
  // Prevent window scroll while mobile nav menu is open
  useLockBodyScroll();

  return (
    <div
      className="w-screen h-screen fixed top-0 left-0 overflow-y-scroll z-10 pb-6"
      style={{ backgroundColor: navbar.color }}
    >
      <div className="container h-full flex flex-col">
        {/* Top section */}
        <div className="flex flex-row justify-between py-2 items-center">
          {/* Company logo */}
          <p className="h-auto w-32 my-4">
            <NextImage width="75" height="75" media={navbar.textLogo} />
          </p>
          {/* Close button */}
          <button onClick={closeSelf} className="py-1 px-1">
            <MdClose
              className="h-8 w-auto"
              style={{ color: navbar.textColor }}
            />
          </button>
        </div>
        {/* Bottom section */}
        <div className="flex flex-col justify-end w-9/12 mx-auto">
          <ul className="flex flex-col list-none gap-6 items-baseline text-xl mb-10">
            {navbar.links.map((navLink) => (
              <li key={navLink.id} className="block w-full" onClick={closeSelf}>
                <CustomLink link={navLink}>
                  <div
                    className="py-6 flex flex-row justify-between items-center"
                    style={{ color: navbar.textColor }}
                  >
                    <span>{navLink.text}</span>
                    <MdChevronRight className="h-8 w-auto" />
                  </div>
                </CustomLink>
              </li>
            ))}
          </ul>
          <ButtonLink
            button={navbar.button}
            appearance={getButtonAppearance(navbar.button.type, "light")}
          />
        </div>
      </div>
    </div>
  );
};

MobileNavMenu.propTypes = {
  navbar: PropTypes.shape({
    logo: mediaPropTypes,
    links: PropTypes.arrayOf(linkPropTypes),
    button: buttonLinkPropTypes,
  }),
  closeSelf: PropTypes.func,
};

export default MobileNavMenu;
