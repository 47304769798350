import Link from "next/link";
import PropTypes from "prop-types";
import { linkPropTypes } from "utils/types";
import { Typography } from "@mui/material";

const CustomLink = ({ link, children, className }) => {
  const isInternalLink = link.url.startsWith("/");
  const isInPageScrollLink = link.url.includes("#");

  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return (
      <Link className={className} href={link.url}>
        {children}
      </Link>
    );
  }

  // Plain <a> tags for external links
  if (link.newTab) {
    return (
      <a
        href={link.url}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  // Smooth scroll if it's in-page anchor link
  if (isInPageScrollLink) {
    return (
      <Typography
        className={className}
        onClick={() => {
          const el = document.getElementById(link.url.slice(1));
          return el.scrollIntoView({ behavior: "smooth", block: "start" });
        }}
        sx={{ cursor: "pointer" }}
        variant="h5"
      >
        {children}
      </Typography>
    );
  }

  return (
    <a href={link.url} className={className} target="_self">
      {children}
    </a>
  );
};

CustomLink.propTypes = {
  link: linkPropTypes,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CustomLink;
