import ErrorPage from "next/error";
import { getGlobalData } from "utils/api";
import { useRouter } from "next/router";
import Layout from "@/components/layout";
import { getLocalizedPaths } from "utils/localize";
import { useEffect, useState } from "react";
import GlobalStyles from "@mui/material/GlobalStyles";
import { DefaultSeo } from "next-seo";
import { getStrapiMedia } from "../utils/media";
import Head from "next/head";
import dynamic from "next/dynamic";
import Loader from "@/components/elements/loader";

const Sections = dynamic(() => import("@/components/sections"), {
  loading: () => <Loader />,
  ssr: false,
});

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

const DynamicPage = ({ sections, preview, global, pageContext }) => {
  const router = useRouter();
  const [mounted, setMounted] = useState(false);

  const { metadata, favicon, metaTitleSuffix, backgroundColor } =
    global.attributes;

  useEffect(() => {
    setMounted(true);
  }, []);

  // Check if the required data was provided
  if ((!router.isFallback && !global.attributes.contentSections?.length) || !global) {
    return <ErrorPage statusCode={404} />;
  }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>;
  }

  return (
    <Layout global={global} pageContext={pageContext}>
      <Head>
        <link
          rel="shortcut icon"
          href={getStrapiMedia(favicon.data.attributes.url)}
        />
      </Head>
      <GlobalStyles
        styles={{
          body: { backgroundColor: backgroundColor },
        }}
      />
      <DefaultSeo
        additionalMetaTags={[
          {
            property: "keywords",
            content: "pepe coin,pepe coin website, meme coin, frogo",
          },
        ]}
        titleTemplate={`%s | ${metaTitleSuffix}`}
        title={metadata?.metaTitle}
        description={metadata?.metaDescription}
        openGraph={{
          images: Object.values(
            metadata.shareImage.data.attributes.formats
          ).map((image) => {
            return {
              url: getStrapiMedia(image.url),
              width: image.width,
              height: image.height,
            };
          }),
        }}
        twitter={{
          cardType: metadata.twitterCardType,
          handle: metadata.twitterUsername,
        }}
      />
      {/* Display content sections */}
      {mounted && (
        <Sections
          sections={global.attributes.contentSections}
          preview={preview}
        />
      )}
    </Layout>
  );
};

export async function getStaticProps(context) {
  const { params, locale, locales, defaultLocale, preview = null } = context;

  const globalLocale = await getGlobalData(locale);
  const { contentSections, metadata } = globalLocale.data.attributes;

  const pageContext = {
    locale,
    locales,
    defaultLocale,
  };

  const localizedPaths = getLocalizedPaths(pageContext);

  return {
    props: {
      revalidate: 60,
      preview,
      sections: contentSections,
      metadata,
      global: globalLocale.data,
      pageContext: {
        ...pageContext,
        localizedPaths,
      },
    },
  };
}

export default DynamicPage;
